/* import { Link } from "gatsby"
import PropTypes from "prop-types" */
import React from "react"

import { Container, Col, Row } from "react-bootstrap"
import { useStaticQuery, graphql } from "gatsby"

/* import { FaMapMarkerAlt , FaPhone } from "react-icons/fa"; */

import { MdPhone, MdPlace, MdEmail } from "react-icons/md"

import MyStyles from "./footer.module.css"

import { Link } from "gatsby"

const MyCol = ({ left, right }) => {
  return (
    <>
      <Col
        md={2}
        sm={2}
        xs={2}
        className={`align-items-center text-right ${MyStyles.col_left}`}
      >
        <Row className="h-100 align-items-center text-right">
          <Col md={2} sm={2} xs={2}>
            {left}
          </Col>
        </Row>
      </Col>

      <Col className={`text-left ${MyStyles.col_right}`}>{right}</Col>
    </>
  )
}

var data_f = [
  "Corso Umberto I, 211",
  "93100 Caltanissetta (CL),",
  "Tel : +39 0934-583822",
  "Fax :+39 0934-583725",
  "Mail : ",
  "P.Iva 01693950857 - REA/91738",
  "Privacy & Cookie Policy",
  "Sito realizzato da Riccardo Rizzo",
]

const Indirizzo = () => {
  const right_content = () => {
    return (
      <>
        {data_f[0]}
        <br />
        {data_f[1]}
      </>
    )
  }
  return (
    <Row>
      <MyCol
        left={<MdPlace className={MyStyles.icon_style} />}
        right={right_content()}
      />
    </Row>
  )
}

const Telefono = () => {
  const right_content = () => {
    return (
      <>
        {data_f[2]}
        <br />
        {data_f[3]}
      </>
    )
  }
  return (
    <Row className="align-items-center">
      <MyCol
        left={<MdPhone className={MyStyles.icon_style} />}
        right={right_content()}
      ></MyCol>
    </Row>
  )
}

const Mail = () => {
  return (
    <Row className="align-items-center">
      <MyCol
        left={<MdEmail className={MyStyles.icon_style} />}
        right={
          <>
            {data_f[4]}
            <Link to="/contatti" className="text-white">
              <u>Contatti</u>
            </Link>
            {" - "}
            <Link to="/lavora_con_noi" className="text-white">
              <u>Lavora con noi</u>
            </Link>
          </>
        }
      ></MyCol>
    </Row>
  )
}
const PivaPrivacy = () => {
  return (
    <Row
      style={{
        paddingTop: `1rem`,
      }}
    >
      <Col>
        {data_f[5]}
        <br />
        {data_f[6]}
      </Col>
      <Col></Col>
      <Col>{data_f[7]}</Col>
    </Row>
  )
}

function Footer() {
  const { site } = useStaticQuery(
    graphql`
      query {
        site {
          siteMetadata {
            title
            description
            author
            keywords
            siteUrl
          }
        }
      }
    `
  )

  return (
    <>
      <Container fluid className={MyStyles.container_a}>
        <Container className={MyStyles.container_b}>
          <Row>
            <Col>
              <b>{site.siteMetadata.title}</b>
            </Col>
            <Col></Col>
            <Col></Col>
          </Row>

          <Row className={`align-items-center ${MyStyles.row_container}`}>
            <Col xs={12} sm={12} md={4}>
              <Indirizzo />
            </Col>
            <Col xs={12} sm={12} md={4}>
              <Telefono />
            </Col>
            <Col xs={12} sm={12} md={4}>
              <Mail />
            </Col>
          </Row>

          <PivaPrivacy />
        </Container>
      </Container>
    </>
  )
}
/* 
const Footer = ({ siteTitle }) => (

  


  <>
    <Container
      style={{
        paddingTop: `1.5rem`,
        paddingBottom: `1.5rem`,
      }}
    >
      <Row>
        <Col>Archimede S.r.l. {siteTitle}</Col>
        <Col></Col>
        <Col></Col>
      </Row>

      <Row>
        <Col>
          Corso Umberto I, 211
          <br />
          93100 Caltanissetta (CL)
        </Col>
        <Col>
          Tel : +39 0934-583822
          <br />
          Fax :+39 0934-583725
        </Col>
        <Col>Mail : info@archimede-srl.com</Col>
      </Row>

      <Row>
        <Col>
          P.Iva 01693950857 - REA/91738
          <br />
          Privacy & Cookie Policy 
        </Col>
        <Col>
        </Col>
        <Col>Sito realizzato da Riccardo Rizzo</Col>
      </Row>
    </Container>
  </>
) */

export default Footer
