import React from "react"
import { graphql } from "gatsby"

// import Layout from "../../../components/layouts/layout"
// import SEO from "../../../components/seo"
// import Header4 from "../../../components/contents/header4"
// import CdaContent from "../../../content/cda/cda_content"
import Layout from "../../../components/layouts/layout"
import SEO from "../../../components/seo"
// import Header4 from "../../../components/contents/header4"
import Header1Fluid from "../../../components/contents/header1Fluid"


import Content1 from "../../../content/ricercaesviluppo/horizon/index"
const HorizonPage = ({ data, location }) => (
  /* console.log(data.site, data.site.siteMetadata.cda), */
  <Layout
    header={
      <Header1Fluid
        src_sfondo={data.placeholderImage2.childImageSharp.fluid}
        data_img={data.placeholderImage.childImageSharp.fixed}
        regex_path_sfondo="/ricercaesviluppo/horizon/header/"
        title="Horizon"
        className="a1"
      />
    }
    location={location}
  >
    <SEO
      title={data.site.siteMetadata.horizon.title}
      pathname={data.site.siteMetadata.horizon.link_page}
    />

      <div>
        <Content1 />
    </div>
  </Layout>
    
)

export const query = graphql`
  {
    placeholderImage: file(relativePath: { eq: "loghi/horizon.jpg" }) {
      childImageSharp {
        fixed(width: 400, jpegQuality: 80) {
          ...GatsbyImageSharpFixed
        }
      }
    }
    placeholderImage2: file(relativePath: { eq: "ricercaesviluppo/horizon/header.jpg" }) {
      childImageSharp {
        fluid(quality: 80, maxWidth: 1200) {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }
    site {
      siteMetadata {
        horizon {
          title
          seo_title
          link_page
        }
      }
    }
  }
`

export default HorizonPage
