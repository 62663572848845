import { Link } from "gatsby"
import PropTypes from "prop-types"
import React from "react"

import { Container, Col, Row, Image } from "react-bootstrap"

import HeaderStyles from "./header1.module.css"

/* import Img from "gatsby-image" */

import BackgroundImage from "gatsby-background-image"

const Header1 = ({ src_sfondo, data_img, className, title, regex_path_sfondo }) => {
  return (
    /*  */
    <BackgroundImage
      id={HeaderStyles.HeaderBackground}
      Tag="section"
      className={className}
      fluid={src_sfondo}
      backgroundColor={`#040e18`}
    >
      {/*  */}
      <div
        id={HeaderStyles.HeaderColorBackground}
        className="h-100 align-item-center"
      >
        <Container fluid className="vh-100">
          <Row className="h-100 align-items-center">
            <Col></Col>
            {/*  */}
            <Col
              id={HeaderStyles.DivHeader}
              xs={12}
              sm={7}
              md={6}
              lg={5}
              xl={4}
              className="text-center"
            >
              <Link id={HeaderStyles.LinkTitleHeader} to="/">
                <Image src={data_img.src} fluid alt="" />
                {/* <Img fixed={data_img} alt="" /> */}
              </Link>
            </Col>
            <Col></Col>
          </Row>
        </Container>
      </div>
    </BackgroundImage>
  )
}

Header1.propTypes = {
  title: PropTypes.string,
  src_sfondo: PropTypes.object,
  data_img: PropTypes.object,
  regex_path_sfondo: PropTypes.string.isRequired,
}

Header1.defaultProps = {
  title: ``,
  src_sfondo: {},
  data_img: {},
}

export default Header1
