import React from "react"
import { StaticQuery, graphql } from "gatsby"
import { Row, Col } from "react-bootstrap"

// import Image from "react-bootstrap/Image"
import Img from "gatsby-image"
// import { Link } from "gatsby"

let color_col_table = `1px solid #cccccc`;
let color_title = `#1c39bb`;
let color_title_table = `#1c39bb`;


const ContentPage2 = ({ className = "" }) => (
    <StaticQuery
    query={graphql`
      {
        qr_code_image: file(relativePath: { eq: "ricercaesviluppo/horizon/qr-code-horizon.jpg" }) {
            childImageSharp {
              fluid(maxWidth: 500, jpegQuality: 80) {
                ...GatsbyImageSharpFluid
              }
            }
          }
      }
    `}
    render={data => (
    <>  
        <div className={`px-5 pt-5 ${className}`}>
                {/* <h3 className="text-left">Presentazione</h3> */}
                {/* <hr /> */}
                <h6>
                    TECNOLOGIA INNOVATIVA PER LA VALORIZZAZIONE E RECUPERO DI RIFIUTI SPECIALI PERICOLOSI E NON PERICOLOSI, NONCHE’ DI RSU, CON PRODUZIONE DI LIGHT-OIL, TRAMITE TRATTAMENTO IN H20 SUPERCRITICA
                </h6>
                <h1 style={{color: color_title }}>SUPERCRITICAL WATER WASTE TREATMENT PLANT</h1>
                <hr /> 
                <Row>
                    <Col md={12} lg={4}>
                        <Row className="h-100 align-items-center">
                            <Col></Col>
                            <Col xs={7} md={7} lg={7}>
                                <a href="https://www.ponic.gov.it/sites/PON/opendata/detail?resource_id=81aea496-e2c3-4739-8768-267ff0b52c5b&codice_progetto=F%2F050294%2F00%2FX32" target="_blank" rel="noopener noreferrer">
                                    <Img fluid={data.qr_code_image.childImageSharp.fluid} title="www.ponic.gov.it - F/050294/00/X32 - detail" />
                                </a>
                            </Col>
                            <Col></Col>
                        </Row>
                    </Col>
                    <Col md={12} lg={8} className="py-4">
                        <p className="_02 paragraph">
                            Una nuova tecnologia nata dal desiderio della Archimede di offrire un servizio innovativo ed unico nel suo genere: lo smaltimento e recupero di rifiuti organici tramite una tecnologia sicura, ambientalmente sostenibile (zero diossine e polveri sottili), in grado di produrre biodiesel, anidride carbonica e azoto per usi industriali. 
                        </p>
                        <p className="_02 paragraph">
                            SUPERCRITICAL WATER WASTE TREATMENT PLANT è un progetto tecnologicamente avanzato, inventato e brevettato tramite il trattamento in acqua supercritica, derivante direttamente dalla ricerca scientifica condotta dalla Archimede da circa un triennio.
                        </p>
                    </Col>
                </Row>
        </div>

        <div className={`px-5 pt-2 ${className}`}>
            <div className="center-block">
                <Row className="p-2 mb-2 text-center">
                    <Col className="py-4 pt-4" style={{border: color_col_table }}>
                        <Row> <Col> <h5 className="mt-2" style={{color: color_title_table }}>CODICE PROGETTO</h5> </Col> </Row>
                        <Row> <Col> F/050294/00/X32 </Col> </Row>
                    </Col>
                </Row>
                <Row className="p-2 mb-2 text-center">
                    <Col lg={6} style={{border: color_col_table }}>
                        <Row>
                            <Col><Row className="h-100 align-items-center"><Col><h5 className="my-4 pt-4" style={{color: color_title_table }}>OBIETTIVI</h5></Col></Row></Col>
                        </Row>
                        <Row>
                            <Col className="mx-3 p-3">
                                <h6>OR1 – Modellazione Tecnico Territoriale e di Processo</h6>
                                    <p>L’obiettivo realizzativo OR1 ha avuto come oggetto studi inerenti la filiera degli elementi di input all’impianto ossia la filiera dei rifiuti del settore Waste to Energy, Oil&Gas e Chemicals e la filiera degli elementi di output quali il “Light Oil”, “CO2” e “N2”.</p>
                                <h6>OR2 – Definizione del Processo</h6>
                                    <p>Con l’obiettivo realizzativo 2 sono stati condotti studi attinenti la simulazione dei processi in riferimento ai tre casi studio riguardanti il settore “Waste to Energy”, “Oil&Gas” e “Chemicals”.</p>
                                <h6>OR3 – Ricerche di laboratorio</h6>
                                    <p>Per quanto concerne gli obiettivi prefissati con l’attività di ricerca prevista su OR3 si rappresenta che sui prodotti oggetto di interesse ai fini della proposta progettuale, sono state condotte prove sperimentali di pirolisi di asfalto bituminoso di raffineria (fornito dalla raffineria ENI di Livorno).</p>
                                <h6>OR4 – Sviluppo Prototipo</h6>
                                    <p>In merito all’obiettivo realizzativo 4, sono state svolte attività di ingegneria finalizzate alla pianificazione e all’esecuzione degli stress test dell’impianto pilota da 600 t/h previste dal progetto.</p>
                                <h6>OR6 – Coordinamento Scientifico Programma</h6>
                                    <p>In merito all’obiettivo realizzativo 6, sono stati raggiunti gli obiettivi finali del previsti in sede di proposta progettuale, tra cui l’espletamento delle attività di coordinamento e monitoraggio tecnico-scientifico dei diversi aspetti degli ambiti di ricerca, le attività di consulenza per la definizione delle soluzioni più idonee per il superamento di criticità relative agli aspetti di processo sia in termini di ricerca industriale che di sviluppo sperimentale, consulenza per la definizione delle soluzioni più idonee per il superamento di criticità relative agli aspetti ambientali e di sicurezza dell’impianto prototipale.</p>
                            </Col>
                        </Row>
                    </Col>
                    <Col lg={6} style={{border: color_col_table }}>
                        <Row>
                            <Col><Row className="h-100 align-items-center"><Col><h5 className="my-4 pt-4" style={{color: color_title_table }}>RISULTATI RAGGIUNTI</h5></Col></Row></Col>
                        </Row>
                        <Row>
                            <Col  className="mx-3 p-4">
                                <p>In definitiva, osservando il percorso intrapreso e i risultati ottenuti, si può certamente affermare che il programma condotto apre di fatto nuove vie di sviluppo e di diversificazione delle attività per la Archimede Srl. Alcuni dei risultati della ricerca, in particolare quelli sul light oil, sono davvero promettenti e in tal senso la Archimede ha già cominciato il processo di industrializzazione avviando lo studio e la redazione di un progetto definitivo da sottoporre agli enti preposti per la realizzazione di un impianto di recupero di rifiuti plastici, finalizzato alla produzione di carburanti secondo la Direttiva 2014/94/UE del 22 ottobre 2014 e cioè “carburanti sintetici, che sostituiscono diesel, benzina e jet fuel, possono essere prodotti a partire da diverse materie prime, convertendo biomassa, gas, carbone o rifiuti di plastica in combustibili liquidi, metano e demetilerete (DME)”.</p>
                                <p>Tale nuovo scenario non sarebbe stato nemmeno ipotizzabile se la Archimede non avesse condotto il progetto Horizon 2020 e in tal senso è risultato determinante il contributo messo a disposizione dallo Stato per sostenere le spese di ricerca e sviluppo, che altrimenti non avrebbero potuto essere sostenute. Alla luce di quanto sopra si giudica molto positiva la valutazione sui risultati raggiunti che sono certamente meritevoli di ulteriori sviluppi di industrializzazione.</p>
                            </Col>
                        </Row>
                    </Col>
                </Row>

                <Row className="p-2 mb-2 text-center">
                    <Col className="py-4 pt-4" style={{border: color_col_table }}>
                        <Row> <Col> <h5 className="mt-2" style={{color: color_title_table }}>COSTO TOTALE PROGETTO</h5> </Col> </Row>
                        <Row> <Col> 1.427.675,25 € </Col> </Row>
                    </Col>
                </Row>
                <Row className="p-2 mb-2 text-center">
                    <Col className="py-4 pt-4" style={{border: color_col_table }}>
                        <Row> <Col> <h5 className="mt-2" style={{color: color_title_table }}>CONTRIBUTO PON</h5> </Col> </Row>
                        <Row> <Col> 837.888,91 € </Col> </Row>
                    </Col>
                </Row>
                <Row className="p-2 mb-2 text-center">
                    <Col className="py-4 pt-4" style={{border: color_col_table }}>
                        <Row> <Col> <h5 className="mt-2" style={{color: color_title_table }}>CUP</h5> </Col> </Row>
                        <Row> <Col> B98I17000460008 </Col> </Row>
                    </Col>
                </Row>
                <Row className="p-2 mb-2 text-center">
                    <Col className="py-4 pt-4" style={{border: color_col_table }}>
                        <Row> <Col> <h5 className="mt-2" style={{color: color_title_table }}>DATA INIZIO</h5> </Col> </Row>
                        <Row> <Col> 05-04-2017 </Col> </Row>
                    </Col>
                </Row>
                <Row className="p-2 mb-2 text-center">
                    <Col className="py-4 pt-4" style={{border: color_col_table }}>
                        <Row> <Col> <h5 className="mt-2" style={{color: color_title_table }}>DATA FINE</h5> </Col> </Row>
                        <Row> <Col> 04-03-2020 </Col> </Row>
                    </Col>
                </Row>
            </div>
        </div>
    </>

    )}
    />
)

export default ContentPage2
