import React from "react"
import { Container } from "react-bootstrap"

import Content1 from "./content1_mod"
import Header1 from "./header1"
import Footer1 from "./footer1"


// import { Link } from "gatsby"

const IndexContentPage = () => (
  <>
    <Container>
      <div className="pt-5" >
        <Header1 />
      </div>
    </Container>

    <Container><Content1 /></Container>
    
    <Container>
      <div className="pt-5 pb-5" >
        <Footer1 />
      </div>
    </Container>


    <hr />

    
    {/* <Container className="mt-3">
      <div className={`px-5 `}>
            <p className="_02 paragraph">Alcuni dei nostri principali lavori “chiavi in mano”:</p>
      </div>
    </Container>
    <Container fluid className="mt-4">
      <Content4 />
    </Container> */}



    {/* <hr></hr>
    <Container>
      <h3 className="text-left">Progetti</h3>
      <ul>
        <li>
          <Link to="/projects/qhoelet/biofeedstock"> Biofeedstock </Link>
        </li>
      </ul>
      
    </Container> */}
  </>
)

export default IndexContentPage
