import React from "react"
import { StaticQuery, graphql } from "gatsby"
import { Row, Col } from "react-bootstrap"

// import Image from "react-bootstrap/Image"
import Img from "gatsby-image"
// import { Link } from "gatsby"

// let color_col_table = `1px solid #cccccc`;
// let color_title = `#1c39bb`;
// let color_title_table = `#1c39bb`;


const ContentPage = ({ className = "" }) => (
    <StaticQuery
    query={graphql`
      {
        file_image: file(relativePath: { eq: "ricercaesviluppo/horizon/content/ue.jpg" }) {
            childImageSharp {
              fluid(maxWidth: 800, jpegQuality: 90) {
                ...GatsbyImageSharpFluid
              }
            }
          }
      }
    `}
    render={data => (
    <>  
        <Row>
            <Col>
                <Row className="h-100 align-items-center">
                    <Col></Col>
                    <Col xs={7} md={7} lg={5}>
                        <a href="https://europa.eu/european-union/index_it" target="_blank" rel="noopener noreferrer">
                            <Img  fluid={data.file_image.childImageSharp.fluid} title="europa.eu" />
                        </a>
                    </Col>
                    <Col></Col>
                </Row>
            </Col>
        </Row>
    </>

    )}
    />
)

export default ContentPage
